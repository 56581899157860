import { ReactElement, useMemo } from "react";
import { useWallet } from "@suiet/wallet-kit";
import clsx from "clsx";
import { ExternalLinkIcon } from "components/atoms/icons/ExternalLinkIcon";
import { Modal } from "components/atoms/Modal";
import partition from "lodash/partition";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const RECOMMENDED_WALLETS = ["Sui Wallet", "Martian Sui Wallet", "OKX Wallet"];
const WHITELIST_WALLETS = [...RECOMMENDED_WALLETS, "Suiet", "Ethos Wallet", "Morphis Wallet"];

const CUSTOM_WALLETS = [
  {
    name: "OKX Wallet",
    iconUrl:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJDSURBVHgB7Zq9jtpAEMfHlhEgQLiioXEkoAGECwoKxMcTRHmC5E3IoyRPkPAEkI7unJYmTgEFTYwA8a3NTKScLnCHN6c9r1e3P2llWQy7M/s1Gv1twCP0ej37dDq9x+Zut1t3t9vZjDEHIiSRSPg4ZpDL5fxkMvn1cDh8m0wmfugfO53OoFQq/crn8wxfY9EymQyrVCqMfHvScZx1p9ls3pFxXBy/bKlUipGPrVbLuQqAfsCliq3zl0H84zwtjQrOw4Mt1W63P5LvBm2d+Xz+YzqdgkqUy+WgWCy+Mc/nc282m4FqLBYL+3g8fjDxenq72WxANZbLJeA13zDX67UDioL5ybXwafMYu64Ltn3bdDweQ5R97fd7GyhBQMipx4POeEDHIu2LfDdBIGGz+hJ9CQ1ABjoA2egAZPM6AgiCAEQhsi/C4jHyPA/6/f5NG3Ks2+3CYDC4aTccDrn6ojG54MnEvG00GoVmWLIRNZ7wTCwDHYBsdACy0QHIhiuRETxlICWpMMhGZHmqS8qH6JLyGegAZKMDkI0uKf8X4SWlaZo+Pp1bRrwlJU8ZKLIvUjKh0WiQ3sRUbNVq9c5Ebew7KEo2m/1p4jJ4qAmDaqDQBzj5XyiAT4VCQezJigAU+IDU+z8vJFnGWeC+bKQV/5VZ71FV6L7PA3gg3tXrdQ+DgLhC+75Wq3no69P3MC0NFQpx2lL04Ql9gHK1bRDjsSBIvScBnDTk1WrlGIZBorIDEYJj+rhdgnQ67VmWRe0zlplXl81vcyEt0rSoYDUAAAAASUVORK5CYII=",
    downloadUrl: {
      browserExtension: "https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge",
    },
  },
];

type IWallet = {
  name: string;
  iconUrl: string;
  downloadUrl: {
    browserExtension?: string | undefined;
  };
  installed: boolean | undefined;
};

function WalletItem({
  wallet,
  recommended,
  onSelect,
}: {
  wallet: IWallet;
  recommended: boolean;
  onSelect: (name: string) => void;
}): ReactElement {
  return (
    <div
      className={clsx(
        "p-2 flex items-center justify-between space-x-6 rounded-lg",
        wallet.installed ? "hover:bg-pGreen-500 hover:bg-opacity-10 cursor-pointer" : "",
      )}
      onClick={wallet.installed ? (): void => onSelect(wallet.name) : undefined}
    >
      <div className="flex items-center space-x-4">
        <img alt={wallet.name} className="w-8 h-8 rounded-full shrink-0" src={wallet.iconUrl} />
        <div>
          <div className="text-pNeutral-800 font-medium">{wallet.name}</div>
          {recommended && <div className="text-sm text-pNeutral-500">(Recommended)</div>}
        </div>
      </div>
      {!wallet.installed && (
        <a
          className="bg-pGreen-500 bg-opacity-20 py-0.5 px-2 text-xs rounded-md space-x-1.5 hover:underline cursor-pointer"
          href={(wallet as IWallet).downloadUrl?.browserExtension ?? (wallet.downloadUrl as string)}
          rel="noreferrer noopener"
          target="_blank"
        >
          <span>Not installed</span>
          <ExternalLinkIcon className="h-3 w-3 inline-block align-middle" />
        </a>
      )}
    </div>
  );
}

function comperator(a: IWallet, b: IWallet): number {
  const hasA = RECOMMENDED_WALLETS.includes(a.name);
  const hasB = RECOMMENDED_WALLETS.includes(b.name);
  if (hasA && !hasB) {
    return -1;
  }
  if (hasB && !hasA) {
    return 1;
  }
  return 0;
}

export function SelectWalletModal({ isOpen, onClose }: Props): ReactElement {
  const suietWallets = useWallet();

  const handleSelectWallet = (name: string): void => {
    suietWallets.select(name);
    onClose();
  };

  const configuredWallets = useMemo(() => {
    const customWallets = CUSTOM_WALLETS.map((wl) => {
      const _wl = suietWallets.detectedWallets.find((w) => w.name === wl.name);
      return (
        _wl ?? {
          ...wl,
          installed: false,
        }
      );
    });
    const wlWallets = [...suietWallets.configuredWallets, ...customWallets].filter((w) =>
      WHITELIST_WALLETS.includes(w.name),
    );
    const [installed, unInstalled] = partition(wlWallets, (wl) => wl.installed);
    installed.sort(comperator);
    unInstalled.sort(comperator);
    return [...installed, ...unInstalled];
  }, [suietWallets.configuredWallets, suietWallets.detectedWallets]);

  return (
    <Modal isOpen={isOpen} title="Select Wallet" onClose={onClose}>
      <div className="space-y-0.5">
        {configuredWallets.map((wl) => (
          <WalletItem
            key={wl.name}
            recommended={RECOMMENDED_WALLETS.includes(wl.name)}
            wallet={wl}
            onSelect={handleSelectWallet}
          />
        ))}
      </div>
    </Modal>
  );
}
